import React, { useRef, useCallback, useEffect, useState } from "react";

import spaceInvader from './../../images/space_invader.png';
import spaceship from './../../images/spaceship.png';

import * as entities from './../../model/entities';
import * as styles from './canvasStyles';


type ShapeCanvasProps = {
    shapes: Array<entities.Shape>;
    paused: boolean;
    frameTime: number;
}

type DisplayDevice = {
    height: number,
    width: number
};

/**
* NOTE: all PNGs in src/images should be 50x37
*/

const spaceshipImage = new Image(50, 37);
spaceshipImage.src = spaceship;

const invaderImage = new Image(50, 37);
invaderImage.src = spaceInvader;


export const ShapeCanvas = (props: ShapeCanvasProps): JSX.Element => {

    const canvasMain = useRef<HTMLCanvasElement>(null);
    const [frames, setFrames] = useState(0);

    /**
     * redraws all shapes passed in to the element
     */
    const draw = useCallback((context: CanvasRenderingContext2D, frameCount: number) => {
        // clear the previous contents of the canvas
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);

        const display: DisplayDevice = {
            height: window.innerHeight,
            width: window.innerWidth
        }

        props.shapes.forEach(shape => {

            if (shape !== undefined) {
                if (shape.status === "appearing") {
                    switch(shape.ShapeType) {

                        case "invader": {
                            const invader: entities.Invader = shape as entities.Invader;
                            context.beginPath();

                            const normalizedY = display.height * invader.RelativePosition.Y;
                            const normalizedX = display.width * invader.RelativePosition.X;

                            invader.GraphicsOrigin.X = normalizedX;
                            invader.GraphicsOrigin.Y = normalizedY;

                            context.drawImage(
                                invaderImage,
                                invader.GraphicsOrigin.X,
                                invader.GraphicsOrigin.Y
                            );

                            break;
                        }

                        case "spaceship": {
                            const spaceship: entities.Spaceship = shape as entities.Spaceship;

                            context.beginPath();

                            const normalizedY = display.height * spaceship.RelativePosition.Y;
                            const normalizedX = display.width * spaceship.RelativePosition.X;

                            spaceship.GraphicsOrigin.X = normalizedX;
                            spaceship.GraphicsOrigin.Y = normalizedY;

                            context.drawImage(
                                spaceshipImage,
                                spaceship.GraphicsOrigin.X,
                                spaceship.GraphicsOrigin.Y
                            );

                            break;
                        }

                        default: {
                            throw new Error(`Strange shape type received: ${shape.ShapeType}`);
                        }
                    }
                }
            }
        });
    }, [props.shapes]);

    /**
     * the component is not mounted yet when we try to access the canvas through the ref,
     * so the first time this is called, canvasMain is still equal to its initial value (null).
     * Once mounted on the DOM, canvasMain will return a canvas and JS can make it draw.
     */
    useEffect(() => {
        const interval = setInterval(async () => {
            if (canvasMain.current) {
                const canvas = canvasMain.current;

                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;

                setFrames(frames + 1);

                const context = canvas.getContext('2d');

                if (context !== null) {
                    let animationFrameId: number = 0;

                    const render = async () => {
                        draw(context, frames);
                        animationFrameId = window.requestAnimationFrame(render);
                    }

                    await render();

                    return () => {
                        window.cancelAnimationFrame(animationFrameId);
                    }
                }
            }
        }, props.frameTime);

        return () => clearInterval(interval);
    }, [frames, setFrames, draw, props.frameTime]);

    return (
        <styles.shapeCanvas ref={canvasMain} {...props} data-shapes={JSON.stringify(props.shapes)}/>
    );
}
