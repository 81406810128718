import styled from "styled-components";

export const TextStyle = styled.div`
  background-color: #ffffff;
  font-family: Inter, serif;
  font-weight: 500;
  padding: 5px;
  font-size: 2em;
  text-align: center;
  margin: 0
`;
