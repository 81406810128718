import { assertNever } from "../../../../training-site/src/helpers";
import CopyIcon from './assets/img/svg/copy.svg';

export type NotifierPosition = 'top' | 'middle' | 'bottom';

export type NotifierConfig = {
    title?: string,
    bodyText?: string,
    showDenyButton?: boolean,
    showCancelButton?: boolean,
    showConfirmButton?: boolean,
    denyButtonText?: string,
    cancelButtonText?: string,
    confirmButtonText?: string,
    position?: NotifierPosition,
    countDownSecond?: number,
    overrideBody?: HTMLElement,
}

export type NotifierResult = {
    isConfirmed?: boolean,
    isDenied?: boolean,
    isCancel?: boolean
}

export type ElementFactoryConfig = Partial<CSSStyleDeclaration> & {
    textContent?: string,
    overrideBody?: HTMLElement
}

export class Notifier {
    public static show(message: string): Promise<NotifierResult | undefined>;
    public static show(config: NotifierConfig): Promise<NotifierResult | undefined>;
    public static async show(config: string | NotifierConfig): Promise<NotifierResult | undefined> {
        let baseConfig: NotifierConfig = {};
        if (typeof config === 'string') {
            baseConfig.title = config;
        }
        else {
            baseConfig = Object.assign(baseConfig, config);
        }

        const notifier = Notifier.notifyFactory(baseConfig);

        return notifier.show();
    }

    public static async toast(config: string | NotifierConfig): Promise<void> {
        let baseConfig: NotifierConfig = {};
        if (typeof config === 'string') {
            baseConfig.title = config;
        }
        else {
            baseConfig = Object.assign(baseConfig, config);
        }

        const notifier = Notifier.notifyFactory(baseConfig);

        return notifier.toast();
    }

    public static showErrorWindow(workerId: string, loginId: string): Promise<NotifierResult | undefined> {
        const rootContainer = document.createElement('div');

        const bodyTextElement = document.createElement('div');
        bodyTextElement.style.fontWeight = '400';
        bodyTextElement.style.padding = '5px';
        bodyTextElement.style.fontSize = '16px';
        bodyTextElement.style.lineHeight = '24px';
        bodyTextElement.style.color = '#323233';
        bodyTextElement.style.textAlign = 'left';
        bodyTextElement.style.margin = '24px 0px 0px';

        const emailSubject = `[${loginId}] Facing an MTurk technical issue`;
        let emailBody = `Hi Amplified,%0d%0dI am facing a technical issue with Amplified Intelligence's MTurk task. My worker ID is ${workerId}.%0d%0d[please describe your issue below]`;
        bodyTextElement.innerHTML = 'Sorry, there was a technical issue. Please ';
        bodyTextElement.innerHTML += `<a style="color: #007041;" href="mailto:amplifiedmturk@gmail.com?subject=${emailSubject}&body=${emailBody}">contact us directly</a>.`;
        bodyTextElement.innerHTML += ` to get your completion code.`;

        rootContainer.appendChild(bodyTextElement);

        return Notifier.show({
            title: 'There is an error',
            overrideBody: rootContainer,
            confirmButtonText: 'Done',
        });
    }

    public static showCompleteWindow(
        completionCode: string,
        workerId: string,
        loginId: string
    ): Promise<NotifierResult | undefined> {
        const rootContainer = document.createElement('div');

        const bodyTextElement = document.createElement('div');
        bodyTextElement.style.fontWeight = '400';
        bodyTextElement.style.padding = '5px';
        bodyTextElement.style.fontSize = '16px';
        bodyTextElement.style.lineHeight = '24px';
        bodyTextElement.style.color = '#323233';
        bodyTextElement.style.textAlign = 'left';
        bodyTextElement.style.margin = '24px 0px 0px';
        bodyTextElement.textContent = 'To claim your reward, simply copy the code below and close this browser window. Then, paste the code into the input field in MTurk to submit the HIT. If you have any issues claiming your reward, '

        const emailLinkElement = document.createElement('a');
        emailLinkElement.textContent = 'contact us directly.';
        emailLinkElement.style.color = '#007041';
        const emailSubject = `[${loginId}] Facing an MTurk technical issue`;
        let emailBody = `Hi Amplified,%0d%0dI am facing a technical issue with Amplified Intelligence's MTurk task. My worker ID is ${workerId}.%0d%0d[please describe your issue below]`;
        emailLinkElement.href = `mailto:amplifiedmturk@gmail.com?subject=subject=${emailSubject}&body=${emailBody}`;
        bodyTextElement.appendChild(emailLinkElement);

        const completionCodeContainer = document.createElement('div');
        completionCodeContainer.style.display = 'flex';
        completionCodeContainer.style.justifyContent = 'flex-start';
        completionCodeContainer.style.alignItems = 'center';
        completionCodeContainer.style.marginTop = '26px';

        const completionCodeElement = document.createElement('div');
        completionCodeElement.style.padding = '8px 20px 8px 12px';
        completionCodeElement.style.fontSize = '36px';
        completionCodeElement.style.lineHeight = '40px';
        completionCodeElement.style.fontWeight = '700';
        completionCodeElement.style.borderRadius = '6px';
        completionCodeElement.style.border = '1px solid #e6e7e8';
        completionCodeElement.textContent = completionCode;

        const copyIcon = document.createElement('img');
        copyIcon.src = CopyIcon;
        copyIcon.style.marginLeft = '23.75px';
        copyIcon.setAttribute('height', '19.5px');
        copyIcon.setAttribute('width', '16.5px');

        const copyButton = document.createElement('div');
        copyButton.style.padding = '8px 16px 8px 7.75px';
        copyButton.style.fontSize = '16px';
        copyButton.style.lineHeight = '24px';
        copyButton.style.fontWeight = '600';
        copyButton.style.color = '#e86a3f';
        copyButton.style.cursor = 'pointer';
        copyButton.textContent = 'Copy';
        copyButton.addEventListener('click', () => {
            if (navigator.clipboard !== undefined) {
                copyButton.addEventListener('click', () => {
                    navigator.clipboard.writeText(completionCode)
                        .then(() => {
                            alert('Code copied, please close this window and paste into the input box in Mturk');
                        })
                        .catch(e => {
                            alert(`
                            Ooops something wrong.\n
                            Sorry but please select the completion code and copy.
                        `);
                        });
                });
            }
            else {
                alert(`
                    Oooops seem your browser doesn't support copy by click.\n
                    Sorry but please select the completion code and copy.
                `);
            }
        });
        copyButton.click();

        completionCodeContainer.appendChild(completionCodeElement);
        completionCodeContainer.appendChild(copyIcon);
        completionCodeContainer.appendChild(copyButton);
        rootContainer.appendChild(bodyTextElement);
        rootContainer.appendChild(completionCodeContainer);

        return Notifier.show({
            title: 'Great job! You completed the study session successfully.',
            overrideBody: rootContainer,
            confirmButtonText: 'Done',
        });
    }

    private static notifyFactory(config: NotifierConfig): Notifier {
        const baseConfig: NotifierConfig = Object.assign({
            showCancelButton: false,
            showDenyButton: false,
            showConfirmButton: true,
            bodyText: "",
            title: ""
        }, config);

        return new Notifier(baseConfig);
    }

    private config: NotifierConfig;
    private titleText: string;
    private bodyText: string;
    private showDenyButton: boolean;
    private showCancelButton: boolean;
    private showConfirmButton: boolean;
    private denyButtonText: string;
    private confirmButtonText: string;
    private cancelButtonText: string;
    private readonly position: NotifierPosition;
    private modalBackdrop?: HTMLElement;
    private modal?: HTMLElement;
    private denyButton?: HTMLElement;
    private cancelButton?: HTMLElement;
    private confirmButton?: HTMLElement;
    private buttonContainer?: HTMLElement;
    private titleElement?: HTMLElement;
    private bodyTextElement?: HTMLElement;
    private counterDownMilliSecond?: number;
    private counterTimeoutNumber?: NodeJS.Timeout;
    private overrideBody?: HTMLElement
    private toastElement?: HTMLElement

    constructor(config: NotifierConfig) {
        this.config = config;
        this.titleText = config.title ?? "";
        this.bodyText = config.bodyText ?? "";
        this.showDenyButton = config.showDenyButton ?? false;
        this.showCancelButton = config.showCancelButton ?? false;
        this.showConfirmButton = config.showConfirmButton ?? true;
        this.denyButtonText = config.denyButtonText ?? "Deny";
        this.confirmButtonText = config.confirmButtonText ?? "Confirm";
        this.cancelButtonText = config.cancelButtonText ?? "Cancel";
        this.position = config.position ?? "middle";
        this.counterDownMilliSecond = config.countDownSecond;
        this.overrideBody = config.overrideBody;
    }

    private static buttonFactory(config?: ElementFactoryConfig): HTMLElement {
        const baseConfig = Object.assign({
            backgroundColor: '#007041',
            padding: '0.5em 1em',
            fontSize: '1.5em',
            lineHeight: '2em',
            borderRadius: '6px',
            color: '#fff',
            textAlign: 'center',
            textContent: "",
        }, config);

        const button = document.createElement('div');

        button.style.backgroundColor = baseConfig.backgroundColor;
        button.style.padding = baseConfig.padding;
        button.style.fontSize = baseConfig.fontSize;
        button.style.lineHeight = baseConfig.lineHeight;
        button.style.borderRadius = baseConfig.borderRadius;
        button.style.color = baseConfig.color;
        button.style.textAlign = baseConfig.textAlign;
        button.textContent = baseConfig.textContent;

        return button;
    }

    private static modalFactory(config?: ElementFactoryConfig): HTMLElement {
        const baseConfig = Object.assign({
            backgroundColor: '#ffffff',
            padding: '16px 24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            borderRadius: '5px',
            width: '600px'
        }, config);
        const modal = document.createElement('div');

        modal.style.padding = baseConfig.padding;
        modal.style.display = baseConfig.display;
        modal.style.flexDirection = baseConfig.flexDirection;
        modal.style.alignItems = baseConfig.alignItems;
        modal.style.backgroundColor = baseConfig.backgroundColor;
        modal.style.borderRadius = baseConfig.borderRadius;
        modal.style.width = baseConfig.width;

        return modal;
    }

    private static textElementFactory(config?: ElementFactoryConfig): HTMLElement {
        if (config?.overrideBody instanceof HTMLElement) return config?.overrideBody;

        const baseConfig = Object.assign({
            backgroundColor: '#ffffff',
            textContent: "",
            fontWeight: '500',
            padding: '5px',
            fontSize: '2em',
            textAlign: 'center',
            margin: '0',
            color: '#323233'
        }, config);

        const textElement = document.createElement('div');

        textElement.style.fontWeight = baseConfig.fontWeight;
        textElement.style.padding = baseConfig.padding;
        textElement.style.fontSize = baseConfig.fontSize;
        textElement.style.textAlign = baseConfig.textAlign;
        textElement.style.margin = baseConfig.margin;
        textElement.style.color = baseConfig.color;
        textElement.innerHTML = baseConfig.textContent;

        return textElement;
    }

    private static backdropFactory(config?: { backgroundColor?: string }): HTMLElement {
        const baseConfig = Object.assign({
            backgroundColor: 'rgba(68,68,68,0.75)',
            height: window.innerHeight + 'px',
            width: window.innerWidth + 'px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '999999'
        }, config);

        const backdrop = document.createElement('div');

        backdrop.style.height = baseConfig.height;
        backdrop.style.width = baseConfig.width;
        backdrop.style.display = baseConfig.display;
        backdrop.style.flexDirection = baseConfig.flexDirection;
        backdrop.style.justifyContent = baseConfig.justifyContent;
        backdrop.style.alignItems = baseConfig.alignItems;
        backdrop.style.position = baseConfig.position;
        backdrop.style.top = baseConfig.top;
        backdrop.style.left = baseConfig.left;
        backdrop.style.fontFamily = 'Inter,serif';
        backdrop.style.zIndex = baseConfig.zIndex;
        backdrop.style.backgroundColor = baseConfig.backgroundColor;

        return backdrop;
    }

    private static containerFactory(config?: ElementFactoryConfig): HTMLElement {
        const baseConfig = Object.assign({
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '2em',
            gap: '1em',
            fontSize: '16px',
            lineHeight: '24px'
        }, config);

        const container = document.createElement('div');
        container.style.display = baseConfig.display;
        container.style.justifyContent = baseConfig.justifyContent;
        container.style.marginTop = baseConfig.marginTop;
        container.style.gap = baseConfig.gap;
        container.style.fontSize = baseConfig.fontSize;
        container.style.lineHeight = baseConfig.lineHeight;

        return container;
    }

    private startWindowResizeObserve(): void {
        window.addEventListener('resize', () => {
            if (this.modalBackdrop == null) return;

            this.modalBackdrop.style.height = window.innerHeight + 'px';
            this.modalBackdrop.style.width = window.innerWidth + 'px';
        });
    }

    private initDom() {
        this.buttonContainer = Notifier.containerFactory({
            marginTop: '32px',
            justifyContent: 'flex-end'
        });

        if (this.config.showDenyButton) {
            this.denyButton = Notifier.buttonFactory({
                textContent: this.denyButtonText,
                backgroundColor: '#fff',
                color: '#e53e3e',
                fontSize: '16px',
                lineHeight: '24px',
                padding: '8px 16px',
            });
            this.buttonContainer.appendChild(this.denyButton);
        }

        if (this.config.showConfirmButton) {
            this.confirmButton = Notifier.buttonFactory({
                textContent: this.confirmButtonText,
                backgroundColor: '#007041',
                fontSize: '16px',
                lineHeight: '24px',
                padding: '8px 16px'
            });
            this.buttonContainer.appendChild(this.confirmButton);
        }

        if (this.config.showCancelButton) {
            this.cancelButton = Notifier.buttonFactory({
                textContent: this.cancelButtonText,
                backgroundColor: '#83786f',
                fontSize: '16px',
                lineHeight: '24px',
                padding: '8px 16px'
            });
            this.buttonContainer.appendChild(this.cancelButton);
        }

        this.titleElement = Notifier.textElementFactory({
            textContent: this.titleText,
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '28px',
            textAlign: 'left'
        });

        this.bodyTextElement = Notifier.textElementFactory({
            fontWeight: '400',
            fontSize: '1em',
            padding: '5px',
            textContent: this.bodyText,
            textAlign: 'left',
            margin: '24px 0 0 0',
            overrideBody: this.overrideBody
        });

        this.modal = Notifier.modalFactory()
        this.modal.appendChild(this.titleElement);
        this.modal.appendChild(this.bodyTextElement);
        this.modal.appendChild(this.buttonContainer);

        this.modalBackdrop = Notifier.backdropFactory();
        this.modalBackdrop.id = `atrace-notifier`;
        this.modalBackdrop.appendChild(this.modal);

        this.changeModalPosition(this.position);
        this.startWindowResizeObserve();
    }

    private changeModalPosition(position: NotifierPosition): void {
        if (this.modal == null) {
            throw new Error('The modal is not initialized');
        }

        if (this.modalBackdrop == null) {
            throw new Error('The container is not initialized');
        }

        this.modal.style.left = '0';
        this.modal.style.right = '0';

        switch (position) {
            case "top":
                this.modalBackdrop.style.justifyContent = 'flex-start';
                break;
            case "middle":
                this.modalBackdrop.style.justifyContent = 'center';
                break;
            case "bottom":
                this.modalBackdrop.style.justifyContent = 'flex-end';
                break;
            default:
                assertNever(position);
        }
    }

    private deconstruct(): void {
        if (this.modalBackdrop == null) return;

        const parent = this.modalBackdrop.parentElement;

        if (parent == null) return;

        parent.removeChild(this.modalBackdrop);
    }

    private deconstructToast(): void {
        if (this.toastElement == null) return;

        const parent = this.toastElement.parentElement;

        if (parent == null) return;

        parent.removeChild(this.toastElement);
    }

    public async show(): Promise<NotifierResult> {
        if (document.querySelector('#atrace-notifier') !== null) {
            // Only One notifier can exist in the same time
            return Promise.resolve({});
        }

        this.initDom();
        document.body.appendChild(this.modalBackdrop!);

        return new Promise((resolve) => {
            this.denyButton?.addEventListener('click', async () => {
                clearTimeout(this.counterTimeoutNumber);
                this.deconstruct();

                resolve({ isDenied: true });
            }, { once: true });

            this.cancelButton?.addEventListener('click', () => {
                clearTimeout(this.counterTimeoutNumber);
                this.deconstruct();

                resolve({ isDenied: true });
            }, { once: true });

            this.confirmButton?.addEventListener('click', () => {
                clearTimeout(this.counterTimeoutNumber);
                this.deconstruct();

                resolve({ isConfirmed: true });
            }, { once: true });

            if (typeof this.counterDownMilliSecond === 'number') {
                this.counterTimeoutNumber = setTimeout(() => {
                    this.deconstruct();
                    resolve({});
                }, this.counterDownMilliSecond);
            }
        });
    }

    private static toastContainerFactory(config?: ElementFactoryConfig): HTMLElement {
        const baseConfig = Object.assign({
            position: "fixed",
            top: "5%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#FCEDEC",
            border: "1px solid #ccc",
            boxShadow: "0 0 10px #ccc",
            zIndex: "99999",
            fontStyle: "#663C00",
            fontWeight: "500",
            fontFamily: "Inter,serif",
            borderRadius: "3px",
            padding: "0.1rem 3rem",
        }, config ?? {});

        const container = document.createElement('dvi');

        container.style.position = baseConfig.position;
        container.style.top = baseConfig.top;
        container.style.left = baseConfig.left;
        container.style.transform = baseConfig.transform;
        container.style.padding = baseConfig.padding;
        container.style.background = baseConfig.background;
        container.style.border = baseConfig.border;
        container.style.boxShadow = baseConfig.boxShadow;
        container.style.zIndex = baseConfig.zIndex;
        container.style.fontStyle = baseConfig.fontStyle;
        container.style.fontWeight = baseConfig.fontWeight;
        container.style.fontFamily = baseConfig.fontFamily;
        container.style.borderRadius = baseConfig.borderRadius;
        container.style.padding = baseConfig.padding;

        return container;
    }

    private initToast(): void {
        let contentText = Notifier.textElementFactory({
            textContent: this.titleText,
            textAlign: 'center',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '28px'
        });

        this.toastElement = Notifier.toastContainerFactory();

        this.toastElement.appendChild(contentText);
    }

    public toast(): void {
        if (document.querySelector('#atrace-notifier') !== null) {
            // Only One toast can exist in the same time
            this.deconstructToast();
        }

        this.initToast();

        if (this.toastElement === undefined) return;

        this.counterTimeoutNumber = setTimeout(() => {
            this.deconstructToast();
        }, this.counterDownMilliSecond ?? 10000);

        document.body.appendChild(this.toastElement);
    }
}