import * as Sentry from '@sentry/browser';
import { SentryTag } from "./types";

const platformDsns = {
    trainingSite: 'https://f6437e6d2c974426a8fefc17f6a3f756@o225955.ingest.sentry.io/4504805981945856',
    chromeExtension: 'https://ed55b22b615b43f983cb4a613d68017a@o225955.ingest.sentry.io/4504948458127360',
} as const

type SentryPlatform = keyof typeof platformDsns

export function setUser(id: string) {
    Sentry.setUser({ id });
}

export function init(tag: SentryTag, platform: SentryPlatform, version?: string): void {
    const dsn = platformDsns[platform];

    Sentry.init({
        dsn,
        environment: process.env.NODE_ENV,
        attachStacktrace: true,
        release: version
    });

    Sentry.setTag(tag, platform);
}

export function captureException(error: Error): void {
    if (process.env.NODE_ENV !== 'production') return;
    Sentry.captureException(error);
}

export function breadcrumb(message: string) {
    if (process.env.NODE_ENV !== 'production') return;
    Sentry.addBreadcrumb({
        level: Sentry.Severity.Info,
        message: message
    })
}

export { Sentry };