import { JSONRect, RoiMetaVideoState } from "./types/JSON/JSONCommon"
import { DataPoint } from "./types/JSON/JSONFrameData";

export type IAtraceMessageBase<_IAtraceMessageType extends IAtraceMessageType> = {
    type: _IAtraceMessageType
}

export type IAtraceMessageType = 
    | "trainingDotData"
    | "roiState"
    | "recordingState"

export type RecordingStateData = {
    recording: boolean
}

export type RoiMeta = {
    advertId?: string,
    advertType: string,
    contentRect: JSONRect,
    videoState?: RoiMetaVideoState
}

export type RoiStateData = {
    hasFullscreenRoi: boolean,
    ROIRects: Record<string, JSONRect>,
    ROIMetas: Record<string, RoiMeta>
}

export type RecordingStateMessage = IAtraceMessageBase<"recordingState"> & {
    timestamp: number,
    data: RecordingStateData
}

export type RoiStateMessage = IAtraceMessageBase<"roiState"> & {
    timestamp: number,
    data: RoiStateData
}

export type TrainingDotMessage = IAtraceMessageBase<"trainingDotData"> & {
    dots: DataPoint[]
}

export type IAtraceMessage = 
    | RecordingStateMessage
    | RoiStateMessage
    | TrainingDotMessage

export interface IMessageCallback {
    onAtraceMessage(message: IAtraceMessage): void;
}

export class MessageHandler {

    private callback: IMessageCallback;

    constructor(messageCallback: IMessageCallback) {
        this.callback = messageCallback;
        window.addEventListener('message', (events) => {
            const data = events.data as IAtraceMessage;
            this.callback.onAtraceMessage(data);
        })
    }
}