import styled from "styled-components";

export const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const ContentWrapper = styled.div`
    text-align: center;
`;

export const GuidelineTitle = styled.div`
    font-family: Inter, serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #323233;
    text-align: center;
    padding-bottom: 10px;
`;

export const IconWrapper = styled.div`
    margin-top: 21px;
    margin-bottom: 21px;
`;

export const BoldParagraph = styled.div`
    font-weight: 500;
`;

export const Paragraph = styled.div`
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
`;

export const ButtonWrapper = styled.div`
    margin-top: 88px;
    display: flex;
    justify-content: center;
`;

export const NextButton = styled.button`
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px 6px 12px;
    border-radius: 6px;
    background: #007041;
    font-family: "Inter";
    border: 1px solid #007041;
    &.adjust {
        margin-left: 157px;
    }
`;

export const PrevButton = styled(NextButton)`
    color: #007041;
    background: none;
    margin-right: 8px;
    &.remove {
        display: none;
    }
`;

export const DotsContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    padding-top: 2em;
`;

export const Dots = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #007041;
    margin: 0 5px;
    background: none;
    &.dot {
        opacity: 0.5;
    }
    &.dot-active {
        background: #007041;
    }
`;

export const ImgContainer = styled.div`
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
`;
