import { Shape } from '../model/shapeBase';
import { useState, useEffect, useCallback } from 'react';
import { getLevelShapes } from './levelDefinitions';


export interface ShapeFactory {
    startGenerator(): void;
    activeShape: Shape | undefined;
    previousShape: Shape | undefined;
}

type Props = {
    level: number,
    setLevel: (newLevel: number) => void,
    started: boolean,
    setStarted: (newStatus: boolean) => void
    paused: boolean,
}


/**
 * React hook for maintaining a collection of shapes
 * @returns
 */
export const UseShapeFactory = (props: Props): ShapeFactory => {

    const [allShapes, setAllShapes] = useState(new Array<Shape>(
        ...getLevelShapes(props.level))
    );

    const [activeShape, setActiveShape] = useState<Shape>();
    const [previousShape, setPreviousShape] = useState<Shape>();

    /**
     * helper to manage shape activation & deactivation
     */
    const activateRandomShape = useCallback(() => {
        // update active and previous shapes
        let inactiveShapes = allShapes.filter(s => s.status === "invisible");

        if (inactiveShapes.length > 0) {

            // update previous shape
            if (activeShape !== undefined) {
                activeShape.status = "disappearing";
                setPreviousShape(activeShape);
            }

            // update active shape
            const index = Math.floor(Math.random() * inactiveShapes.length);
            const luckyShape = inactiveShapes[index];
            luckyShape.status = "appearing"
            setActiveShape(luckyShape);
        }

        /** level complete */
        else {
            console.debug(`Level ${props.level} complete.`)

            // bumping the level to MAX_LEVEL will signal the app to stop the game
            const newLevel = props.level + 1;

            // trying to load shapes for an undefined level will throw an error
            if (newLevel >= 0 && newLevel <= 3) {
                setAllShapes(getLevelShapes(newLevel));
            }

            // load the next level
            props.setLevel(newLevel);
        }
    }, [activeShape, previousShape]);

    /**
     * continually rotates through available points until have been shown once.
     */
    useEffect(() => {
        if (props.started === true && props.paused === false) {
            const interval = setInterval(() => activateRandomShape(), 3500);
            return () => clearInterval(interval);
        }
    }, [props, activateRandomShape]);

    return {
        startGenerator: () => props.setStarted(true),
        activeShape: activeShape,
        previousShape: previousShape
    }
}
