declare global {
    interface Window {
        chrome?: object,
        safari?: object,
    }
}

export const isSafari = window.navigator.userAgent.includes('Safari') && window.safari !== undefined;

export const isChrome = window.navigator.userAgent.includes('Chrome') && window.chrome !== undefined;

// @ts-ignore
export const isFirefox = typeof InstallTrigger !== 'undefined';
