import React, { FC, PropsWithChildren } from "react";
import { ModalStyle } from "./modal.style";
import { Backdrop } from "./backdrop";
import { Text } from "./text";

export const Modal: FC<PropsWithChildren> = (props) => {
    return (
        <Backdrop>
            <ModalStyle>
                <Text>
                    {props.children}
                </Text>
            </ModalStyle>
        </Backdrop>
    );
};