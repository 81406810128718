import styled from 'styled-components';


/**
 * the canvas must observe the device's aspect ratio and therefore may not fill the
 * entire screen.
 */
export const shapeCanvas = styled.canvas`
    height: 100vh;
    width: 100vw;

    object-fit: contain;

    align-items: center;
    margin-left: auto;
    margin-right: auto;
`;
