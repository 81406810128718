export async function sleep(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export function assertNever(param: never): never {
    throw new Error(`Illegal state received, should be never but found ${param}.`);
};

/**
 * Fisher-Yates (aka Knuth) array shuffle algorithm
 *
 * @param array
 * @returns a randomly re-ordered array
 */
export const shuffle = <T extends unknown>(array: Array<T>): Array<T> => {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]
        ];
    }

    return array;
}
