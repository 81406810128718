import styled from "styled-components";

export const ModalStyle = styled.div`
  background-color: #ffffff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 5px;
  width: 600px
`;