import { Shape } from "../model/shapeBase";
import { Position } from "../model/shapes";
import { fixedPositions, randomPositions } from "./positions";
import { shuffle } from "../helpers";
import { AppColor, getRandomColor } from "../view/colorGenerator";
import * as entities from './../model/entities';


const Invader = entities.Invader;
const Spaceship = entities.Spaceship;


export const getRandomShape = (
    position: Position,
    exclude?: Shape
): Shape => {
    const roll = 1 + Math.floor(Math.random() * (6 - 1 + 1));

    let result: Shape;

    switch (roll) {
        case 1:
        case 2:
        case 3: {
            result = new Invader(position);
            break;
        }
        case 4:
        case 5:
        case 6: {
            result = new Spaceship(position);
            break;
        }
        default: {
            throw new Error("bad roll, should be between 1 and 6");
        }
    }

    if (result.color === exclude?.color) {
        let randColor: AppColor = getRandomColor();

        while (randColor === result.color) {
            randColor = getRandomColor();
        }

        result.color = getRandomColor();
    }

    return result;
}

const getRandomPositions = (requested: number): Array<Position> => {
    const results = new Array<Position>();
    let randoms = randomPositions;

    /** ensures that the same point is not included twice. */
    for (let i = 0; i < requested; i++) {
        const min = Math.ceil(0);
        const max = Math.floor(randoms.length-1);
        const index = Math.floor(Math.random() * (max - min + 1)) + min;

        const luckyPosition = randoms[index];
        results.push(luckyPosition);

        randoms = randoms.filter(p => p !== luckyPosition);
    }

    return results;
}

/**
 *
 * level 1 will be the 12 fixed positions.
 */
const getLevelOneShapes = (): Array<Shape> => {
    const shapes = fixedPositions.map(p => getRandomShape(p));
    return shapes;
}

/**
 * level 2 will be a mix of fixed and random positions, in a ratio of 60/40
 */
const getLevelTwoShapes = (): Array<Shape> => {
    const randomPositions = getRandomPositions(8);
    const results = shuffle([...fixedPositions, ...randomPositions]);
    const shapes = results.map(p => getRandomShape(p));

    return shapes;
}

/**
 * level 3 will be a random selection of 30 / 200 of the randomPositions array
  */
const getLevelThreeShapes = (): Array<Shape> => {
    const positions = getRandomPositions(30);
    const shapes = positions.map(p => getRandomShape(p));

    return shapes;
}

export const getLevelShapes = (level: number): Array<Shape> => {
    switch (level) {
        case (1): return getLevelOneShapes();
        case (2): return getLevelTwoShapes();
        case (3): return getLevelThreeShapes();
        default: throw new Error("Please supply a level between 1 and 3");
    }
}