import { Shape } from "./shapeBase";
import { Position, ShapeType } from "./shapes";


export class Spaceship extends Shape {

    private static shipCounter: number = 0;

    protected shape: ShapeType = "spaceship";
    public get ShapeType(): ShapeType { return this.shape; }

    constructor(origin: Position) {
        super();

        this.label = `spaceship_${Spaceship.shipCounter}`;
        Spaceship.shipCounter++;
        this.relativePosition = origin;

        /**
        * NOTE: the space invader raw PNG is 113 x 85 pixels.
        * for performance reasons we are resizing to 100x75
        */
        this.height = 75;
        this.width = 100;
    }

    public Intersects(other: Shape): boolean {
        return false;
    }
}
