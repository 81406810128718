import styled from 'styled-components';


export const modalContainer = styled.div`
    display: inline-block;
    width: 400px;
    margin-left: 40%;
    margin-top: 10%;
`;

export const cardHead = styled.header`
    background-color: #d4d3d2;

    border-radius: 15px 15px 0px 0px;
    border-bottom: 2px solid darkgrey;
    border-left: 2px solid darkgrey;
    border-right: 1px solid darkgrey;
    border-top: 1px solid darkgrey;

    .modal-title {
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
    }

    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const cardTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

/**
 * contains an HTML Canvas Element for rendering 1 sprite
 */
export const sampleImageContainer = styled.span`
    display: inline-block;
    height: 100px;
    width: 100px;
    border: 2px solid #707070;
    border-radius: 10px;
    background-color: white;

    margin-left: 10px;
    margin-right: 10px;

    text-align: center;

    canvas {
        padding-top: 30px;
    }

    :hover {
        background-color: #ebf4fc;
        border: 2px solid black;
        box-shadow: 2px lightblue;
    }
`;

export const cardBody = styled.div`
    background-image: linear-gradient(white, #ebebeb);
    border-left: 2px solid darkgrey;
    border-right: 1px solid darkgrey;

    label {
        font-weight: bold;
        font-size: 15px;
        padding-left: 150px;
        padding-bottom: 10px;
    }
`;

/**
 * contains and positions the HTML Canvas Elements used to render
 * the sprites
 */
export const sampleImages = styled.div`
    padding-top: 20px;
    padding-bottom: 100px;
    padding-top: 10%;
    padding-left: 20%;
`;

export const cardFoot = styled.footer`
    height: 30px;
    background-color: #d4d3d2;
    border-radius: 0px 0px 15px 15px;
    border-top: 2px solid darkgrey;
    border-left: 2px solid darkgrey;
    border-right: 1px solid darkgrey;
    border-bottom: 2px solid darkgrey;
`;

/**
 * a small embedded canvas for drawing shapes
 */
export const shapeCanvas = styled.canvas`
    height: 100%;
    width: 100%;

    object-fit: contain;

    align-items: center;
    margin-left: auto;
    margin-right: auto;
`;
