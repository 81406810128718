import { AppColor } from "./colorGenerator";

/**
 *  WARNING: DO NOT USE, as is this causes performance issues
 */
export const FilterImage = (
    imageData: ImageData,
    color: AppColor
): ImageData => {
    for (var i = 0; i < imageData.data.length; i+= 4) {

        // white is 255, 255, 255
        // so is this pixel black or white?
        if (
            imageData.data[i] !== 255 &&
            imageData.data[i+1] !== 255 &&
            imageData.data[i+2] !== 255
        ){
            // it is a black pixel, replace with the RGB from the color
            imageData.data[i] = color.RGB.red;
            imageData.data[i+1] = color.RGB.green;
            imageData.data[i+2] = color.RGB.blue;
        }
    }

    return imageData;
}
