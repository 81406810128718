import { JSONFrameData, GazeCalibrationDesktopJSONFrameData, DataPoint } from "./types/JSON/JSONFrameData";
import { CameraHelper, Fps } from "./cameraHelper";
import { loadScript } from "./scriptLoader";
import User from "./user";
import { IAtraceMessage, MessageHandler } from "./messageHandler";
import { Video } from "./types/JSON/JSONStaticData";
import { TaskProgress } from "./types/TaskProgress";
import { captureException } from "@atrace/sentry"
import { VIDEO_HEIGHT, VIDEO_WIDTH, RECORDER_MIME_TYPE, TRAINING_SITE_TASK_DIR } from "./constant/constant";
import { Notifier } from "./notification/Notifier";

export default abstract class FrameDataAggregator {
    protected static videoToFrameDataMap: Record<number, JSONFrameData[]> = [];
    protected static cameraHelper: CameraHelper;
    protected static taskDir = TRAINING_SITE_TASK_DIR;
    private static videoExtensionWoDot: string = RECORDER_MIME_TYPE === 'video/webm' ? 'webm' : 'mp4'

    protected static async saveVideoData(data: Blob, index: number, bLocalTesting: boolean) {
        if (bLocalTesting) {
            const dir = `MturkDesktopLocalTest/${User.localTestAssignmentDir}/${this.taskDir}`;
            const key = `${dir}/video-${index}.${this.videoExtensionWoDot}`;
            await User.s3.postBlob(data, 'video/webm', key);
        }
        else {
            const dir = `${User.assignmentDir}/${this.taskDir}`;
            const key = `${dir}/video-${index}.${this.videoExtensionWoDot}`;
            await User.s3.postBlob(data, RECORDER_MIME_TYPE, key);
            console.log(`${key} uploaded`);
        }

        this.setVideoToStaticData(this.taskDir, index);
    }

    protected static async saveFrameData(data: JSONFrameData[], index: number, bLocalTesting: boolean) {
        if (bLocalTesting) {
            const dir = `MturkDesktopLocalTest/${User.localTestAssignmentDir}/${this.taskDir}`;
            const key = `${dir}/frameData-${index}.json`;
            await User.s3.postObject(data, key);
            return
        }

        const dir = `${User.assignmentDir}/${this.taskDir}`;
        const key = `${dir}/frameData-${index}.json`;

        await User.s3.postObject(data, key);
        console.log('frameData uploaded');

    }

    private static setVideoToStaticData(dir: string, index: number) {
        const video: Video = {
            videoRelativePath: `${dir}/video-${index}.${this.videoExtensionWoDot}`,
            frameDataRelativePath: `${dir}/frameData-${index}.json`,
            attentionMethod: "desktopGaze",
            faceUpOrientation: "top",
            position: "front",
            resolution: {
                height: VIDEO_HEIGHT,
                width: VIDEO_WIDTH
            }
        }

        User.staticData.videos.push(video);
    }
}

export class TrainingSiteAggregator extends FrameDataAggregator {

    private static currentDataPoint: DataPoint;
    private static _finalizeCallback: () => void;
    private static _bLocalTesting: boolean;

    static init(frameRate: Fps, finalizeCallback: () => void, bLocalTesting: boolean) {
        this._finalizeCallback = finalizeCallback;
        this._bLocalTesting = bLocalTesting;
        this.initialise(frameRate)

        this.addListeners()
    }

    static finish() {
        this.cameraHelper?.stopRecording(true);
    }

    static pause() {
        this.cameraHelper?.stopRecording(false);
    }

    static resume() {
        this.cameraHelper?.startRecording();
    }

    private static addListeners() {
        new MessageHandler({
            onAtraceMessage: (message: IAtraceMessage) => {

                switch (message.type) {
                    case "recordingState": {
                        const isRecording = message.data.recording;
                        if (isRecording) {
                            this.cameraHelper?.startRecording();
                        }
                        else {
                            this.cameraHelper?.stopRecording();
                        }

                        break;
                    }

                    case "trainingDotData": {
                        if (message.dots.length <= 0) return;
                        const dot = message.dots[0];
                        this.currentDataPoint = {
                            x: dot.x,
                            y: dot.y,
                            xPercent: dot.xPercent,
                            yPercent: dot.yPercent
                        }

                        break;
                    }
                }
            }
        })
    }

    private static async uploadTaskProgress() {
        if (this._bLocalTesting) return;

        const progress: TaskProgress = {
            taskProgress: {
                completionCode: {
                    completed: true
                }
            }
        }
        const loginIdObj = User.loginIdObj;
        const progressObjKey = `respondentTaskProgress/${loginIdObj.groupKey}/${loginIdObj.code}.json`;
        await User.s3.postObject(progress, progressObjKey, User.s3.configsBucket);
        console.log(`${progressObjKey} uploaded`)
    }

    private static initialise(fps: Fps) {
        this.cameraHelper = new CameraHelper(fps, {
            onFrameCallback: (index: number, timestamp: number, videoIndex: number) => {
                const frame: GazeCalibrationDesktopJSONFrameData = {
                    index: index,
                    timestamp: timestamp,
                    datapoint: this.currentDataPoint
                }

                var frameData = this.videoToFrameDataMap[videoIndex]
                if (frameData == null) {
                    this.videoToFrameDataMap[videoIndex] = [];
                }
                frameData = this.videoToFrameDataMap[videoIndex];
                frameData.push(frame)
            },

            onVideoReady: async (data: Blob, videoIndex: number, bFinalised: boolean) => {
                var frameData = this.videoToFrameDataMap[videoIndex];
                delete this.videoToFrameDataMap[videoIndex];
                const bLocalTesting = TrainingSiteAggregator._bLocalTesting;
                const MaxAttempts = 3;

                async function doUpload(attempts: number) {
                    try {
                        await FrameDataAggregator.saveVideoData(data, videoIndex, bLocalTesting);
                        await FrameDataAggregator.saveFrameData(frameData, videoIndex, bLocalTesting);
                        await User.uploadStaticData(bLocalTesting);
                        await TrainingSiteAggregator.uploadTaskProgress()
                        if (bFinalised) TrainingSiteAggregator._finalizeCallback();
                    }
                    catch (err) {
                        captureException(err as Error);
                        if (attempts === 0) {
                            if (bFinalised) TrainingSiteAggregator._finalizeCallback();
                            return;
                        }
                        await Notifier.show({
                            title: 'Results not uploaded',
                            bodyText: 'Some of your results could not be uploaded. Please make sure you are on a stable network connection and click the try again button.',
                            confirmButtonText: 'Try again',
                        })
                        doUpload(attempts -= 1);
                    }
                }

                doUpload(MaxAttempts);
            }
        });
    }
}