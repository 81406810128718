import S3 from '@atrace/s3';
import { Client, JSONStaticData } from './types/JSON/JSONStaticData';
import { RespondentConfig } from './types/RespondentConfig';
import { getTrainingSiteScreenSize } from './helpers';
import { isCompletionCodeTask } from './typeGuard';
import { LoginId, LoginIdObj } from './types/LoginId';
import { TaskProgress } from './types/TaskProgress';

export default class User {

    private static config: RespondentConfig;
    public static staticData: JSONStaticData;
    public static loginIdObj: LoginIdObj;
    public static loginId: LoginId;
    public static localTestAssignmentDir: string;
    public static assignmentDir: string;
    public static s3: S3;
    public static isSessionCompleted?: boolean;

    public static init(loginId: string, bIsDev: boolean, onDone: (alreadyCompleted: boolean) => void) {
        User.s3 = new S3(bIsDev ? "dev" : "prod", false);
        this.localTestAssignmentDir = new Date().toISOString();

        User.staticData = {
            version: 1, // increment every time the schema is updated
            client: {
                appPlatform: "web",
                appVersion: "1.0.2",
                hostname: window.location.hostname,
                name: "attentionTRACE Desktop Gaze Training",
                measurementUnit: "pixels",
                web: {
                    userAgent: window.navigator.userAgent
                }
            },
            screen: getTrainingSiteScreenSize(),
            videos: []
        }

        try {
            const matches = loginId.match(/[a-zA-Z]+|[0-9]+/g);
            if (matches != null && matches.length == 2) {
                const url = `respondentConfigs/${matches[0]}/${matches[1]}.json`;
                User.s3.getObject(url).then(result => {
                    const config = result as RespondentConfig;
                    User.config = config;
                    User.staticData.respondent = {
                        groupID: config.groupID,
                        assignmentID: config.assignmentID,
                        env: config.env,
                        extraMeta: config.extraMeta
                    }
                    User.loginIdObj = { groupKey: matches[0], code: matches[1] };
                    User.loginId = loginId;

                    // Find an assignment directory.
                    const baseDir = `${User.config.env}/${User.config.respondentID}/${User.config.assignmentID}`;
                    User.findNextAssignmentId().then(id => {
                        User.assignmentDir = `${baseDir}-${id}`;

                        if (bIsDev) {
                            console.warn(
                                'Skipped task progress check as we are running in a DEV environment, please ensure this feature is tested in an E2E test before release')
                            onDone(false);
                            User.isSessionCompleted = false;
                            return;
                        }

                        // Check if User session was already completed.
                        User.s3.doesObjectExist(`respondentTaskProgress/${User.loginIdObj.groupKey}/${User.loginIdObj.code}.json`, User.s3.configsBucket).then(res => {
                            if (!res) {
                                onDone(false);
                                User.isSessionCompleted = false;
                                return;
                            }
                            User.s3.getObject(`respondentTaskProgress/${User.loginIdObj.groupKey}/${User.loginIdObj.code}.json`).then(progress => {
                                const completed = (progress as TaskProgress).taskProgress["completionCode"].completed;
                                User.isSessionCompleted = completed;
                                onDone(completed);
                            })
                        })
                    })
                })
            }
            else {
                console.error("Incorrect loggin id format");
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    private static async findNextAssignmentId(): Promise<number> {
        const key = `${User.config.env}/${User.config.respondentID}/nextAssignmentId.json`
        const hasObj = await User.s3.doesObjectExist(key);
        if (!hasObj) {
            await User.s3.postObject(1 as any, key);
            return 0;
        }

        const curr = await User.s3.getObject(key, User.s3.uploadsBucket) as any;
        const nextId = curr + 1;
        await User.s3.postObject(nextId as any, key);
        return curr;
    }

    public static getCompletionCode(): string | undefined {
        const task = User?.config?.tasks[1];
        if (isCompletionCodeTask(task)) {
            return task.config.completionCode;
        }
    }

    public static async uploadStaticData(bLocalTesting: boolean): Promise<void> {
        if (bLocalTesting) {
            const key = `MturkDesktopLocalTest/${User.localTestAssignmentDir}/staticData.json`;
            await User.s3.postObject(User.staticData, key);
            return
        }
        const key = `${User.assignmentDir}/staticData.json`;
        await User.s3.postObject(User.staticData, key);
    }

    /** MTurk worker ID */
    public static get workerId(): string | undefined {
        return User.config?.extraMeta?.workerId;
    }
}