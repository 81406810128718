import { Notifier } from "@atrace/shared-core";
import { VIDEO_WIDTH, VIDEO_HEIGHT, RECORDER_MIME_TYPE } from "./constant/constant";

export interface IFrameCallback {
    onFrameCallback(index: number, timestamp: number, videoIndex: number): void;
    onVideoReady(data: Blob, videoIndex: number, bFinalised: boolean): void;
}

export type Fps = 5 | 30;

export class CameraHelper {
    private fps: Fps;
    private callback: IFrameCallback;
    private cameraStream: MediaStream | undefined;
    private mediaRecorder: MediaRecorder | undefined;
    private frameIndex: number = -1;
    private currentVideoIndex: number = 0;
    private futureVideoIndex: number = 0;
    private requestData: number = -1;
    private interval: number;
    private bStarted: boolean = false;

    constructor(fps: Fps, frameCallback: IFrameCallback) {
        // Create a video node so we can get user media to access webcam. 
        const videoElement = document.createElement('video');
        videoElement.setAttribute('id', "gum");
        document.body.appendChild(videoElement);

        this.callback = frameCallback;
        this.fps = fps;
        this.interval = Math.floor(1000 / this.fps);

        // Open camera and set resource    
        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                frameRate: { exact: fps },
                width: { exact: VIDEO_WIDTH },
                height: { exact: VIDEO_HEIGHT }
            }
        })
            .then(s => {
                this.cameraStream = s;
                videoElement.srcObject = s;
                this.initialiseRecorder(s);
            })
            .catch(e => {
                Notifier.show({
                    title: 'Camera permission denied',
                    bodyText: 'You have denied access to your camera. Please click the icon in your browser\'s address bar to enable camera permissions. Afterwards, please reload this page.',
                    showConfirmButton: false,
                })
            });
    }

    private initialiseRecorder(s: MediaStream) {
        this.mediaRecorder = new MediaRecorder(s, { mimeType: RECORDER_MIME_TYPE });
        var blobsRecorded: Blob[] = [];

        this.mediaRecorder.onstart = () => {
            this.handleFrames();
        };

        this.mediaRecorder.ondataavailable = (e) => {
            console.warn(`ondataavailable`);
            if (e.data && e.data.size > 0) {
                blobsRecorded.push(e.data);
                if (this.futureVideoIndex == this.currentVideoIndex + 1) {
                    const blobs = blobsRecorded;
                    this.callback.onVideoReady(
                        new Blob(blobs, { type: RECORDER_MIME_TYPE }),
                        this.currentVideoIndex,
                        false
                    );
                    this.currentVideoIndex = this.futureVideoIndex;
                    blobsRecorded = [];
                }
            }
        };

        this.mediaRecorder.onresume = () => {
            this.handleFrames();
        };

        this.mediaRecorder.onpause = () => {
            this.bStarted = false;
            clearInterval(this.requestData);
        };

        this.mediaRecorder.onstop = () => {
            this.bStarted = false;
            clearInterval(this.requestData);
            this.callback.onVideoReady(
                new Blob(blobsRecorded, { type: RECORDER_MIME_TYPE }),
                this.currentVideoIndex,
                true
            );
        }
    }

    private handleFrames() {
        if (this.bStarted) return;
        this.bStarted = true;
        try {
            this.requestData = window.setInterval(() => {
                this.frameIndex += 1;
                const index = this.frameIndex;
                const time = Date.now();
                this.callback.onFrameCallback(index, time, this.currentVideoIndex);
            }, this.interval);
        }
        catch (err) {
            console.log(err);
        }
    }

    public startRecording() {
        const state = this.mediaRecorder?.state;
        switch (state) {
            case "inactive": {
                this.mediaRecorder?.start();
                break;
            }

            case "paused": {
                this.mediaRecorder?.resume();
                break;
            }

            default: {
                break;
            }
        }
    }

    public stopRecording(bFinalised: boolean = false) {
        const state = this.mediaRecorder?.state;
        switch (state) {
            case "recording": {
                if (bFinalised) {
                    this.mediaRecorder?.stop();
                }
                else {
                    this.mediaRecorder?.pause();
                }
                break;
            }

            case "paused": {
                if (bFinalised) {
                    this.cameraStream?.getTracks()[0].stop();
                    this.mediaRecorder?.stop();
                }
                break;
            }

            case "inactive": {
                this.cameraStream?.getTracks()[0].stop();
                break;
            }
        }
    }

    /**
     * Split recorded videos.
     * The caller of the function is responsible of calling splitting based on frame count.
     *
     * Example usage in an aggregator's onFrameCallback:
     *  if (index > 0 && index % VIDEO_FRAME_BUFFER == 0) {
     *      this.cameraHelper?.splitRecording();
     *  }
     *
     * @returns null
     */
    public splitRecording() {
        if (this.futureVideoIndex == this.currentVideoIndex + 1) return;

        // Trigger ondataavailable event of the mediaRecorder.
        this.mediaRecorder?.requestData();
        this.futureVideoIndex = this.currentVideoIndex;
        this.futureVideoIndex += 1;
    }
}