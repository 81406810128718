import { createGlobalStyle } from "styled-components";
const InterFont = require('../../static/font/Inter/Inter.ttf');

const GlobalStyle = createGlobalStyle`
  body, div#popup {
    width: 300px;
    margin: 0;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter'), url(${InterFont}) format("opentype");
    font-style: normal;
  }
`;

export default GlobalStyle;