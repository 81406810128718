import React, { FC, useState } from "react";
import { Box, Modal, Grid, CardMedia } from "@mui/material";
import {
    BoldParagraph,
    ButtonWrapper,
    ContentWrapper,
    Dots,
    DotsContainer,
    GuidelineTitle,
    IconWrapper,
    NextButton,
    Paragraph,
    PrevButton,
    style,
} from "./instructions.style";
import Tips1 from "../images/tips1.jpg";
import Tips2 from "../images/tips2.jpg";
import Tips3 from "../images/tips3.jpg";
import Tips4 from "../images/tips4.jpg";
import Tips5 from "../images/tips5.jpg";

const Tips = [
    {
        imageLeft: Tips1,
        imageRight: Tips2,
        title: "Tips for a successful session",
        content:
            "Please ensure the room is well lit, the camera is positioned at eye level and the screen is tilted at a natural angle."
    },
    {
        imageLeft: Tips3,
        imageMid: Tips4,
        imageRight: Tips5,
        title: "Viewing behaviours to avoid",
        content:
            "Please keep a natural angle, and do not position yourself too far from your screen."
    },
];

interface Slideshow {
    items: any;
    close: boolean;
    closeModal: (close: boolean) => void;
}

const Slideshow: FC<Slideshow> = (props) => {
    const [{ items, activeIndex }, setState] = useState({
        items: props.items,
        activeIndex: 0
    });

    const moveTo = (newIndex: number) => () => {
        if (newIndex === -1) {
            // jump from the first icon to the last
            setState((s) => ({ ...s, activeIndex: items.length - 1 }));
            return;
        }
        if (newIndex === items.length) {
            // when reach to the last element, close modal
            props.closeModal(false);
            return;
        }

        setState((s) => ({ ...s, activeIndex: newIndex }));
    };

    const moveDot = (index: any) => () => {
        setState((s) => ({ ...s, activeIndex: index }));
    };

    return (
        <div>
            <ContentWrapper>
                <GuidelineTitle>{items[activeIndex].title}</GuidelineTitle>
                <IconWrapper>
                    {items[activeIndex].imageMid ? (
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 0.5, sm: 1, md: 1.5 }}
                        >
                            <Grid item xs={4}>
                                <CardMedia
                                    component="img"
                                    image={items[activeIndex].imageLeft}
                                    alt="Tips3"
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CardMedia
                                    component="img"
                                    image={items[activeIndex].imageMid}
                                    alt="Tips4"
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CardMedia
                                    component="img"
                                    image={items[activeIndex].imageRight}
                                    alt="Tips5"
                                    loading="lazy"
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 0.5, sm: 1, md: 2 }}
                        >
                            <Grid item xs={6}>
                                <CardMedia
                                    component="img"
                                    image={items[activeIndex].imageLeft}
                                    alt="Tips1"
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CardMedia
                                    component="img"
                                    image={items[activeIndex].imageRight}
                                    alt="Tips2"
                                    loading="lazy"
                                />
                            </Grid>
                        </Grid>
                    )}
                </IconWrapper>
                <Paragraph>
                    <BoldParagraph>
                        {items[activeIndex].content}
                    </BoldParagraph>
                    <DotsContainer>
                        {Array.from({ length: 2 }).map((item, index) => (
                            <Dots
                                key={index}
                                onClick={() => moveDot(index)}
                                className={
                                    activeIndex === index ? "dot-active" : "dot"
                                }
                            ></Dots>
                        ))}
                    </DotsContainer>
                </Paragraph>
                <ButtonWrapper>
                    <PrevButton
                        className={activeIndex === 0 ? "remove" : ""}
                        onClick={moveTo(activeIndex - 1)}
                    >
                        Prev
                    </PrevButton>
                    <NextButton
                        className={activeIndex === 0 ? "adjust" : ""}
                        onClick={moveTo(activeIndex + 1)}
                    >
                        Next
                    </NextButton>
                </ButtonWrapper>
            </ContentWrapper>
        </div>
    );
};

interface Instruction {
    close: boolean;
    closeModal: (close: boolean) => void;
}

const Instructions: FC<Instruction> = ({ closeModal }) => {
    return (
        <div>
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Slideshow
                        items={Tips}
                        closeModal={closeModal}
                        close={false}
                    ></Slideshow>
                </Box>
            </Modal>
        </div>
    );
};

export default Instructions;
