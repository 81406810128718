import { isSafari } from "../browser/util";

/**
 * If want to check is a mime is supported by
 * a browser, use MediaRecorder.isTypeSupported(mime_name).
 */
export const RECORDER_MIME_TYPE = isSafari ? 'video/mp4' : 'video/webm';

export const VIDEO_WIDTH = 1280;
export const VIDEO_HEIGHT = 720;
export const TRAINING_SITE_TASK_DIR = "gazeCalibrationDesktop";

/**
 * Minimum number of frames to record before swapping to a new video
 */
export const VIDEO_FRAME_BUFFER = 100;