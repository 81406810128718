import { Position } from "../model/shapes";

/** verified no duplicates */
export const fixedPositions = Array<Position>(
    { X: .02, Y: .02 },
    { X: .30, Y: .02 },
    { X: .60, Y: .02 },
    { X: .90, Y: .02 },
    { X: .02, Y: .50 },
    { X: .30, Y: .50 },
    { X: .60, Y: .50 },
    { X: .90, Y: .50 },
    { X: .02, Y: .89 },
    { X: .30, Y: .89 },
    { X: .60, Y: .89 },
    { X: .90, Y: .89 }
);

/** verified no duplicates */
export const randomPositions = Array<Position>(
    { "X": 0.7720151276542979, "Y": 0.17107689033859175 },
    { "X": 0.8575555337043579, "Y": 0.08027993366376229 },
    { "X": 0.7576186726633993, "Y": 0.9034539694964481 },
    { "X": 0.7431573385912469, "Y": 0.9077901472693286 },
    { "X": 0.8425347217909138, "Y": 0.9211283717808816 },
    { "X": 0.5552630062936983, "Y": 0.7207373219583362 },
    { "X": 0.46916893810866056, "Y": 0.8583806879614659 },
    { "X": 0.7405681750680304, "Y": 0.9866424327476295 },
    { "X": 0.3778405346591991, "Y": 0.4668546820416186 },
    { "X": 0.5392962234158969, "Y": 0.07268928694092702 },
    { "X": 0.8642360296404101, "Y": 0.31874664045670564 },
    { "X": 0.6396330814337408, "Y": 0.0019638476211818467 },
    { "X": 0.04831870616272815, "Y": 0.12593235675594294 },
    { "X": 0.20661629388518343, "Y": 0.06387186260000988 },
    { "X": 0.26109652519889903, "Y": 0.01475910157336946 },
    { "X": 0.1416242594443997, "Y": 0.9481614628977726 },
    { "X": 0.7272607594902707, "Y": 0.28399777418831507 },
    { "X": 0.39197416173150157, "Y": 0.7407450160483107 },
    { "X": 0.226374849696662, "Y": 0.8236940310145713 },
    { "X": 0.18249532840554572, "Y": 0.18911204303052287 },
    { "X": 0.8850802165783629, "Y": 0.28837556912851925 },
    { "X": 0.12158473785915236, "Y": 0.08997371884920558 },
    { "X": 0.19752719752711934, "Y": 0.6909586887251826 },
    { "X": 0.09103162085755923, "Y": 0.7315883771048128 },
    { "X": 0.5905472315697955, "Y": 0.7902039169232578 },
    { "X": 0.18259399563706213, "Y": 0.5293883548315221 },
    { "X": 0.5037946895524839, "Y": 0.31719986642076226 },
    { "X": 0.9367670109553248, "Y": 0.18388099312409656 },
    { "X": 0.3583987481492843, "Y": 0.014569218925561955 },
    { "X": 0.6827338890821155, "Y": 0.6798942105100534 },
    { "X": 0.4984742995987641, "Y": 0.41705467322013057 },
    { "X": 0.34927528213945935, "Y": 0.23278689767533467 },
    { "X": 0.5884615128610329, "Y": 0.5861581839792875 },
    { "X": 0.9705411162549218, "Y": 0.17506352191382746 },
    { "X": 0.5055656832578102, "Y": 0.09896322568562055 },
    { "X": 0.051480481904895736, "Y": 0.17458560727000383 },
    { "X": 0.010518448043866924, "Y": 0.8515154085981524 },
    { "X": 0.9987142257858639, "Y": 0.6262320904206271 },
    { "X": 0.03307012820076394, "Y": 0.37610218321714317 },
    { "X": 0.30240167925777994, "Y": 0.03875555321062807 },
    { "X": 0.274098755170856, "Y": 0.98068710640448 },
    { "X": 0.9639171715352952, "Y": 0.6060173022251265 },
    { "X": 0.8341195249965387, "Y": 0.8133544468381615 },
    { "X": 0.3980155439848998, "Y": 0.17684144926629286 },
    { "X": 0.2921520482841349, "Y": 0.3943556563377575 },
    { "X": 0.4163883052069617, "Y": 0.7503854997727772 },
    { "X": 0.8562833600228181, "Y": 0.9268757060264448 },
    { "X": 0.7709270655936529, "Y": 0.4672791210529008 },
    { "X": 0.4026179117305271, "Y": 0.030266414952957454 },
    { "X": 0.24804560831699551, "Y": 0.17293489934862416 },
    { "X": 0.19640483031437306, "Y": 0.7558449355381274 },
    { "X": 0.7825845756734973, "Y": 0.4047391930153288 },
    { "X": 0.9570007748950351, "Y": 0.6800509977427367 },
    { "X": 0.9609276871608812, "Y": 0.5530733868919473 },
    { "X": 0.08585887289433614, "Y": 0.588240749657994 },
    { "X": 0.07806355533752507, "Y": 0.588837990456111 },
    { "X": 0.966697865275246, "Y": 0.6149809344947803 },
    { "X": 0.18176439619634777, "Y": 0.6040425492786798 },
    { "X": 0.4819986593475778, "Y": 0.6431711043122144 },
    { "X": 0.6179575035067042, "Y": 0.5481530086971562 },
    { "X": 0.8971563539484397, "Y": 0.7849415098572834 },
    { "X": 0.7647880234669053, "Y": 0.8063184941751069 },
    { "X": 0.48302131426413797, "Y": 0.8635657855934407 },
    { "X": 0.10494085653634211, "Y": 0.1881675682454813 },
    { "X": 0.7670921006190963, "Y": 0.020429366008526628 },
    { "X": 0.7285262922999634, "Y": 0.9309548521000028 },
    { "X": 0.4157392078749671, "Y": 0.7390847471725028 },
    { "X": 0.4125083381132195, "Y": 0.37088686710301255 },
    { "X": 0.7675305544774897, "Y": 0.9060721981705162 },
    { "X": 0.3572950926592857, "Y": 0.6695906035201662 },
    { "X": 0.346036035815499, "Y": 0.640458263476718 },
    { "X": 0.12217174663620955, "Y": 0.4995286182146592 },
    { "X": 0.04658537138774088, "Y": 0.22814802006869983 },
    { "X": 0.26011937256744755, "Y": 0.05808335085469429 },
    { "X": 0.4790336149316534, "Y": 0.30251803670869615 },
    { "X": 0.2268614833076581, "Y": 0.3117333914555305 },
    { "X": 0.963324369517164, "Y": 0.8251147765755262 },
    { "X": 0.8692224003091038, "Y": 0.9572284187653268 },
    { "X": 0.7571691173878901, "Y": 0.480209267799828 },
    { "X": 0.16517169096147133, "Y": 0.8206074846479146 },
    { "X": 0.9158063019455092, "Y": 0.21637154324913288 },
    { "X": 0.5199792795089737, "Y": 0.27121889176439384 },
    { "X": 0.10709462831647976, "Y": 0.996046618079746 },
    { "X": 0.43798358251233904, "Y": 0.40649973179690435 },
    { "X": 0.15651228284480534, "Y": 0.3215494241164656 },
    { "X": 0.29894533324058936, "Y": 0.020876301246225637 },
    { "X": 0.9961796658354689, "Y": 0.8284572578417913 },
    { "X": 0.4240470266052654, "Y": 0.8322905812151216 },
    { "X": 0.9370246336295764, "Y": 0.5215141472613729 },
    { "X": 0.4001461323621587, "Y": 0.2734304247750412 },
    { "X": 0.8132384957862613, "Y": 0.7162095469359728 },
    { "X": 0.6221357547576487, "Y": 0.04401447034859873 },
    { "X": 0.3007925567221008, "Y": 0.6832591186649144 },
    { "X": 0.09497463504269121, "Y": 0.12277646820493371 },
    { "X": 0.36971394420032433, "Y": 0.46160608091467314 },
    { "X": 0.5933633299251662, "Y": 0.6857422954667984 },
    { "X": 0.2885013156069549, "Y": 0.4065546459832088 },
    { "X": 0.6143237038278249, "Y": 0.6352663449780227 },
    { "X": 0.680553742619588, "Y": 0.39774172440676536 },
    { "X": 0.016870714599274805, "Y": 0.3863440767827877 },
    { "X": 0.3820052181799469, "Y": 0.1323597593259347 },
    { "X": 0.3171418984881771, "Y": 0.6146087560421574 },
    { "X": 0.7278245045819958, "Y": 0.24740732848714697 },
    { "X": 0.213083470022434, "Y": 0.7634738435330357 },
    { "X": 0.5860407817983242, "Y": 0.16037636831520663 },
    { "X": 0.40780697364060425, "Y": 0.6908800535416482 },
    { "X": 0.4559460487271805, "Y": 0.7362933718117715 },
    { "X": 0.5137069575856787, "Y": 0.5819781622911855 },
    { "X": 0.9842812924309321, "Y": 0.5832315656803129 },
    { "X": 0.5912264557225102, "Y": 0.41739969365609375 },
    { "X": 0.9819206031378509, "Y": 0.8917469334780661 },
    { "X": 0.7836708834075308, "Y": 0.39972976339691635 },
    { "X": 0.9107823706640998, "Y": 0.6506723360790387 },
    { "X": 0.24549656426010658, "Y": 0.38907766147248 },
    { "X": 0.1924958732071036, "Y": 0.3426487471042652 },
    { "X": 0.558099139567463, "Y": 0.5776532043405058 },
    { "X": 0.6831122282292099, "Y": 0.07159259138756369 },
    { "X": 0.03706070306182618, "Y": 0.15422895379864232 },
    { "X": 0.6506126562778223, "Y": 0.1974571586939422 },
    { "X": 0.8499422434681128, "Y": 0.22662100992418654 },
    { "X": 0.8194135661822386, "Y": 0.05055712499593201 },
    { "X": 0.39871272218850784, "Y": 0.8571511141730261 },
    { "X": 0.17976696205940157, "Y": 0.13686035125699414 },
    { "X": 0.6029161919518853, "Y": 0.4635727181978351 },
    { "X": 0.22871098968743886, "Y": 0.26687872803167223 },
    { "X": 0.31591989763359574, "Y": 0.4540833684383099 },
    { "X": 0.4478249952559583, "Y": 0.09878040054419968 },
    { "X": 0.2557721287517172, "Y": 0.6496436588872971 },
    { "X": 0.32327779660298983, "Y": 0.0013576693997745526 },
    { "X": 0.9293108878152103, "Y": 0.46031005327176366 },
    { "X": 0.1400733869125277, "Y": 0.45827295042627036 },
    { "X": 0.3192473683459438, "Y": 0.5235843224852446 },
    { "X": 0.5286316056292439, "Y": 0.022809030741266945 },
    { "X": 0.8302859468665904, "Y": 0.504514374066251 },
    { "X": 0.36171710919564437, "Y": 0.37986513809321565 },
    { "X": 0.4377560977436532, "Y": 0.10986650598011805 },
    { "X": 0.6452860576132411, "Y": 0.31569146073930887 },
    { "X": 0.5836660119439925, "Y": 0.048023913960012043 },
    { "X": 0.9404273524996616, "Y": 0.5935787460769846 },
    { "X": 0.012409376166824027, "Y": 0.5770186220446509 },
    { "X": 0.2793111610710284, "Y": 0.10934684415744433 },
    { "X": 0.7445092065731969, "Y": 0.6102444200635708 },
    { "X": 0.35282963186141236, "Y": 0.9815438078620093 },
    { "X": 0.9522141603836232, "Y": 0.31726221448143166 },
    { "X": 0.25582681759597903, "Y": 0.8902985992305519 },
    { "X": 0.866975605205963, "Y": 0.1956909950828718 },
    { "X": 0.6621727079300652, "Y": 0.5091385961464798 },
    { "X": 0.6791394381121909, "Y": 0.7266940574672132 },
    { "X": 0.687207307768976, "Y": 0.634771074816171 },
    { "X": 0.31081250449258846, "Y": 0.15318009730170612 },
    { "X": 0.19894353779527285, "Y": 0.17495074545628375 },
    { "X": 0.0249239319328034, "Y": 0.7666419664934152 },
    { "X": 0.846881878996889, "Y": 0.9517671983501845 },
    { "X": 0.19376994433831607, "Y": 0.09228132182435411 },
    { "X": 0.18230617450690922, "Y": 0.8328807260906426 },
    { "X": 0.14409242547018786, "Y": 0.3812682108512435 },
    { "X": 0.6375286459456784, "Y": 0.4874857358597986 },
    { "X": 0.16646086675527783, "Y": 0.5044629384985917 },
    { "X": 0.5062760310871532, "Y": 0.46230777505931697 },
    { "X": 0.25712845671502715, "Y": 0.18258668165979342 },
    { "X": 0.26009430641822795, "Y": 0.7858923250378291 },
    { "X": 0.6439494115696551, "Y": 0.5404273158358421 },
    { "X": 0.4580899904355693, "Y": 0.9425814218437334 },
    { "X": 0.7207080407687763, "Y": 0.5744293754701111 },
    { "X": 0.7970267420250527, "Y": 0.6676804972394743 },
    { "X": 0.027757094241335656, "Y": 0.7487760867690123 },
    { "X": 0.17064304049520507, "Y": 0.011359881101669522 },
    { "X": 0.11791148381666083, "Y": 0.22455252973896767 },
    { "X": 0.10446646713074847, "Y": 0.39493266066763044 },
    { "X": 0.9997459055366009, "Y": 0.8254920587172048 },
    { "X": 0.9076498471686167, "Y": 0.23251938845844666 },
    { "X": 0.8139429108762244, "Y": 0.656314046989247 },
    { "X": 0.4647356339891402, "Y": 0.25411339101473196 },
    { "X": 0.2678672612665889, "Y": 0.16829731247511392 },
    { "X": 0.7429826044014392, "Y": 0.40814924117454665 },
    { "X": 0.8401474959026656, "Y": 0.6570989002218703 },
    { "X": 0.5357576562148301, "Y": 0.060986659744295846 },
    { "X": 0.572685966327219, "Y": 0.5041756760742324 },
    { "X": 0.8592706660038258, "Y": 0.7402124291222885 },
    { "X": 0.7831945041998154, "Y": 0.190775307753005 },
    { "X": 0.8899004587314161, "Y": 0.2945723051368261 },
    { "X": 0.7268234578238932, "Y": 0.8556970555628411 },
    { "X": 0.17763034086773866, "Y": 0.7090764362104343 },
    { "X": 0.11216551265087027, "Y": 0.18268615377786612 },
    { "X": 0.9205944360554561, "Y": 0.9505374684205801 },
    { "X": 0.722231820280945, "Y": 0.9814043867212925 },
    { "X": 0.4610082161925504, "Y": 0.003479546804986189 },
    { "X": 0.42598821033061673, "Y": 0.6333933844284612 },
    { "X": 0.30595582792188525, "Y": 0.4195776614870519 },
    { "X": 0.26232914909357596, "Y": 0.43568304543721204 },
    { "X": 0.4693613446014142, "Y": 0.5852555210954518 },
    { "X": 0.6976977377436415, "Y": 0.5162970588481182 },
    { "X": 0.8567688380816367, "Y": 0.6982003365489418 },
    { "X": 0.27555444062996415, "Y": 0.21343037606283155 },
    { "X": 0.4766637134297995, "Y": 0.8064590109602452 },
    { "X": 0.299203869381919, "Y": 0.53912806925754 },
    { "X": 0.435240705481873, "Y": 0.03533195536205502 },
    { "X": 0.3548286243864407, "Y": 0.48264982193966 },
    { "X": 0.9009371735068972, "Y": 0.729986328538965 },
    { "X": 0.41349139325222684, "Y": 0.10913286022463486 }
);
