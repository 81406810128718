import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getRandomShape } from '../../viewModel/levelDefinitions';
import { shuffle } from '../../helpers';
import { FilterImage } from '../filter';
import spaceInvader from './../../images/space_invader.png';
import spaceship from './../../images/spaceship.png';

import * as styles from './scoringStyles';
import * as entities from './../../model/entities';

type Props = {
    title: string,
    active: boolean,
    level: number,
    wasCorrect: (answer: boolean) => void,
    previousShape: entities.Shape
}

const invaderImage = new Image(50, 37);
invaderImage.src = spaceInvader;

const spaceshipImage = new Image(50, 37);
spaceshipImage.src = spaceship;


export const ScoringModal = (props: React.PropsWithChildren<Props>) => {

    const [shapes, setShapes] = useState<Array<entities.Shape>>(new Array<entities.Shape>());
    const [loaded, setLoaded] = useState(false);

    const canvasOne = useRef<HTMLCanvasElement>(null);
    const canvasTwo = useRef<HTMLCanvasElement>(null);

    // this className controls whether Bluma renders or hides the Modal.
    const isActive: string = props.active ? 'is-active' : '';

    const displayTitle = props.title.toUpperCase();

    useEffect(() => {
        if (loaded === false) {

            // excludes previous shape so we do not get a duplicate
            const randomShape = getRandomShape(
                { X: 0, Y: 0 },
                props.previousShape
            );

            // setting shapes = a new array each time, previous version ended up with
            // array length of 4 due to this hook executing > once.

            let newShapes = new Array<entities.Shape>();
            newShapes.push(randomShape);
            newShapes.push(props.previousShape);

            const shuffledShapes = shuffle(newShapes);
            setShapes(shuffledShapes);

            setLoaded(true);
        }
    }, [loaded, props.previousShape, shapes])

    const draw = useCallback((
        context: CanvasRenderingContext2D,
        shape: entities.Shape,
        frameCount: number
    ) => {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);

        switch(shape.ShapeType) {

            case "invader": {
                context.beginPath();

                context.drawImage(
                    invaderImage,
                    20,
                    5
                );

                let imageData: ImageData = context.getImageData(
                    20,
                    5,
                    50,
                    37
                );

                imageData = FilterImage(imageData, shape.color);

                context.putImageData(
                    imageData,
                    20,
                    5
                );

                break;
            }

            case "spaceship": {
                const spaceship: entities.Spaceship = shape as entities.Spaceship;

                context.beginPath();

                const normalizedY = context.canvas.height * spaceship.RelativePosition.Y;
                const normalizedX = context.canvas.width * spaceship.RelativePosition.X  + 20;

                spaceship.GraphicsOrigin.X = normalizedX;
                spaceship.GraphicsOrigin.Y = normalizedY;

                context.drawImage(
                    spaceshipImage,
                    20,
                    5
                );

                let imageData: ImageData = context.getImageData(
                    20,
                    5,
                    50,
                    40
                );

                imageData = FilterImage(imageData, shape.color);

                context.putImageData(
                    imageData,
                    20,
                    5
                );

                break;
            }
        }

    }, []);

    const framerate = 1000 / 30;

    /**
     * useEffect to make both HTML Canvas Elements render
     * their sprites
     */
    useEffect(() => {
        const interval = setInterval(async () => {

            if (canvasOne.current && canvasTwo.current) {

                const canvasOneCurrent = canvasOne.current;
                canvasOneCurrent.width = 100;
                canvasOneCurrent.height = 100;
                const contextOne = canvasOneCurrent.getContext('2d');

                const canvasTwoCurrent = canvasTwo.current;
                canvasTwoCurrent.width = 100;
                canvasTwoCurrent.height = 100;
                const contextTwo = canvasTwoCurrent.getContext('2d');

                if (contextOne !== null && contextTwo !== null) {

                    let frameCount = 0;
                    let animationFrameId: number = 0;

                    const render = async () => {
                        frameCount++;
                        draw(contextOne, shapes[0], frameCount);
                        draw(contextTwo, shapes[1], frameCount);
                        animationFrameId = window.requestAnimationFrame(render);
                    }

                    await render();

                    return () => {
                        window.cancelAnimationFrame(animationFrameId);
                    }
                }
            }
        }, framerate);

        return () => clearInterval(interval);
    });

    const onPictureClicked = (pictureNumber: number) => {
        switch (pictureNumber) {
            case (0): {
                props.wasCorrect(shapes[0] === props.previousShape);
                break;
            }

            case (1): {
                props.wasCorrect(shapes[1] === props.previousShape);
                break;
            }

            default: break;
        }
    }

    return (
        <div className={`modal ${isActive}`}>
            <styles.modalContainer>
                <div className="modal-background"></div>
                <div className="modal-content">
                    <styles.cardHead className="modal-card-head">
                        <span className="modal-title">
                            {displayTitle}
                        </span>
                    </styles.cardHead>
                    <styles.cardBody className="modal-card-body">
                    {
                        loaded &&

                        <styles.sampleImages>
                            <styles.sampleImageContainer>
                                <styles.shapeCanvas
                                    ref={canvasOne}
                                    onClick={() => onPictureClicked(0)}
                                />
                            </styles.sampleImageContainer>
                            <styles.sampleImageContainer>
                                <styles.shapeCanvas
                                    ref ={canvasTwo}
                                    onClick={() => onPictureClicked(1)}
                                />
                            </styles.sampleImageContainer>
                        </styles.sampleImages>
                    }
                    </styles.cardBody>
                    <styles.cardFoot>
                    </styles.cardFoot>
                </div>
            </styles.modalContainer>
        </div>
    );
}
