import React, { useState, useCallback, useEffect } from 'react';
import { ShapeCanvas } from './view/canvas/shapeCanvas';
import { UseShapeFactory } from './viewModel/shapeFactory';
import { TrainingSiteAggregator } from '@atrace/shared-core/src/frameDataAggregator';
import User from '@atrace/shared-core/src/user';
import { ScoringModal } from './view/scoringModal/scoringModal';
import { SessionScore } from './view/scoringModal/scoreFrame';
import { Notifier } from "@atrace/shared-core/src/notification/Notifier";
import { getLoginId, hasFeature } from "@atrace/shared-core/src/helpers";
import Instructions from "./components/instructions";
import GlobalStyle from "./core/styles/global";
import { Modal } from "./components/modal/modal";
import * as styles from './appStyles';
import * as Sentry from "@atrace/sentry"
import { loadScript } from '@atrace/shared-core/src/scriptLoader';
import { isChrome, isFirefox, isSafari } from "@atrace/shared-core/src/browser/util";

const FRAME_RATE = 30;
const MAX_LEVEL = 2;

if (hasFeature('printConsoleMsg')) {
    console.warn('printConsoleMsg', 'The feature is enabled');
}

const [loginId, bLocalTesting] = getLoginId();
let bIsDev = bLocalTesting || process.env.NODE_ENV !== "production";
if (bLocalTesting) {
    console.info('App is in local testing mode');
}

Sentry.init('MTurk Training App', 'trainingSite');

declare global {
    interface WebkitDocument {
        webkitFullscreenElement?: Element;
    }

    interface MozDocument {
        mozFullScreenElement?: Element;
    }

    interface WebKitHTMLElement {
        webkitRequestFullscreen: () => void | undefined;
        webkitExitFullscreen: () => void | undefined;
    }

    interface MozHTMLElement {
        mozRequestFullScreen: () => void | undefined;
        mozExitFullScreen: () => void | undefined;
    }
}

function App() {
    const [fullScreenRecentlyRequested, setFullScreenRecentlyRequested] = useState(false);
    const [started, setStarted] = useState(false);
    const [level, setLevel] = useState(1);
    const [scoreModalActive, setScoreModalActive] = useState(false);
    const [paused, setPaused] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    const [isSessionCompleted, setIsSessionCompleted] = useState<boolean | undefined>(User.isSessionCompleted);
    const [webScriptLoaded, setWebScriptLoaded] = useState(false);

    useEffect(() => {
        async function loadScriptRetrying() {
            const bRunningLocally = process.env.NODE_ENV !== "production";
            const webscriptURL = bRunningLocally ?
                "http://127.0.0.1:8080/trainingDot.js" :
                "https://atrace-cdn.s3.ap-southeast-2.amazonaws.com/WebPlatform/Bundles/Latest/trainingDot.js"

            try {
                await loadScript(webscriptURL);
                setWebScriptLoaded(true);
            } catch (err) {
                console.error('script failed to load, retrying after 3 seconds...');
                await new Promise<void>(resolve => {
                    setInterval(() => resolve(), 3000)
                })
                loadScriptRetrying();
            }
        }

        loadScriptRetrying();
    }, [setWebScriptLoaded]);

    const [sessionScore] = useState<SessionScore>({
        correctAnswers: 0,
        incorrectAnswers: 0,
        percentCorrect: 0
    });

    const enterFullScreen = useCallback(() => {
        if ((isSafari || isChrome) && !(document as WebkitDocument).webkitFullscreenElement) {
            const element = document.documentElement as unknown as WebKitHTMLElement;
            element.webkitRequestFullscreen();
        }
        else if (isFirefox && !(document as MozDocument).mozFullScreenElement) {
            const element = document.documentElement as unknown as MozHTMLElement;
            element.mozRequestFullScreen();
        }
    }, []);

    const exitFullScreen = useCallback(() => {
        if ((isSafari || isChrome) && (document as WebkitDocument).webkitFullscreenElement) {
            const element = document.documentElement as unknown as WebKitHTMLElement;
            element.webkitExitFullscreen();
        }
        else if (isFirefox && (document as MozDocument).mozFullScreenElement) {
            const element = document.documentElement as unknown as MozHTMLElement;
            element.mozExitFullScreen();
        }
    }, []);

    const showCompleteWindow = useCallback((completionCode: string): void => {
        Notifier.showCompleteWindow(
            completionCode,
            User.workerId ?? "Worker is missing",
            User.loginId ?? "LoginId Id is missing"
        ).catch(e => {console.log(e);});
    }, []);

    const showErrorWindow = useCallback((): void => {
        Notifier.showErrorWindow(
            User.workerId ?? "Worker Id is missing",
            User.loginId ?? "LoginId Id is missing"
        ).then(() => {
            window.location.reload();
        });
    }, []);

    const onDone = useCallback(() => {
        setIsSessionCompleted(true);

        const completionCode = User.getCompletionCode();
        if (typeof completionCode === 'string') {
            showCompleteWindow(completionCode);
        }
        else {
            showErrorWindow();
        }
    }, []);

    /**
     *  deactivates fullScreenRecentlyRequested after 3 seconds
     */
    useEffect(() => {
        const interval = setInterval(() => {
            if (fullScreenRecentlyRequested === true) {
                setFullScreenRecentlyRequested(false);
            }
        }, 3000);

        return clearInterval(interval);
    }, [fullScreenRecentlyRequested, setFullScreenRecentlyRequested]);

    const startLevel = (): void => {
        enterFullScreen();
        setStarted(true);
        setPaused(false);
        startGenerator();
    };

    const pauseLevel = (): void => {
        TrainingSiteAggregator.pause();
        setPaused(true);
        exitFullScreen();
        showWarningWindow();
    };

    const unpauseLevel = (): void => {
        setPaused(false);
        enterFullScreen();
        TrainingSiteAggregator.resume();
    };

    const exitFullscreenHandler = useCallback(() => {
        if (((isSafari || isChrome) && !(document as WebkitDocument).webkitFullscreenElement)
            || (isFirefox && !(document as MozDocument).mozFullScreenElement)) {
            setFullScreenRecentlyRequested(true);
            pauseLevel();
        }
    }, []);

    const keydownHandler = useCallback((e: KeyboardEvent) => {
        if (!fullScreenRecentlyRequested) {
            if (e.key === "Enter") {
                setFullScreenRecentlyRequested(true);

                // if started === true, then they have already started.
                if (started === true) {
                    paused === true ? unpauseLevel() : pauseLevel();
                }

                // they are starting for the first time, enter fullscreen
                else {
                    if (!webScriptLoaded) return;
                    startLevel();
                }
            }
        }
    }, [webScriptLoaded]);

    /** Only add these events once */
    useEffect(() => {
        /**
         * If this event fires and it was because we were exiting fullscreen,
         * then by the time this handler executes document.fullscreenElement will
         * already be null.
         */
        if (isSafari || isChrome) {
            document.addEventListener("webkitfullscreenchange", exitFullscreenHandler);
        }
        else {
            document.addEventListener("fullscreenchange", exitFullscreenHandler);
        }

        /** locks user input for 3 seconds after pressing enter once */
        document.addEventListener("keydown", keydownHandler);
    }, []);

    const {startGenerator, activeShape, previousShape} = UseShapeFactory({
        level: level,
        setLevel: (newLevel: number) => {
            setStarted(false);
            setLevel(newLevel);
        },
        started: started,
        setStarted: (newValue: boolean) => {
            setStarted(newValue);
        },
        paused: paused,
    });

    const showWarningWindow = useCallback(() => {
        Notifier.show({
            title: 'Need to end the study early?',
            bodyText: `<div>To complete this study and claim your rewards, your browser window needs to remain in fullscreen on your primary screen. By moving this to another screen, the study will end automatically and you will not be rewarded for this session. <br><br>Are you sure you want to exit the study early?</div>`,
            showDenyButton: true,
            denyButtonText: 'Stop the study',
            confirmButtonText: 'Return to study'
        }).then((value => {
            if (value == undefined) return;

            if (value.isConfirmed === true) {
                startLevel();
            }
            else if (value.isDenied === true) {
                window.location.reload();
            }
        }));
    }, [])

    /**
     * stops the recorder if we get to max level and game ends
     */
    useEffect(() => {
        if (level >= MAX_LEVEL) {
            TrainingSiteAggregator.finish();
        }
    }, [level]);

    useEffect(() => {
        User.init(loginId, bIsDev, (alreadyCompleted: boolean) => {
            Sentry.setUser(loginId);

            setIsSessionCompleted(alreadyCompleted);

            if (alreadyCompleted && !bLocalTesting) {
                console.info('task was already completed');
                onDone();
                return;
            }

            TrainingSiteAggregator.init(FRAME_RATE, onDone, bLocalTesting);
        });
    }, []);

    const displayStartMessage = (): JSX.Element => {
        const levelMessage = level < MAX_LEVEL ? `LEVEL ${level}` : "Uploading results...";
        const buttonMessage = paused === true ? "Resume Game" : "Press Enter to Start";

        if (levelMessage === 'Uploading results...') {
            if (isSafari || isChrome) {
                document.removeEventListener("webkitfullscreenchange", exitFullscreenHandler);
            } else {
                document.removeEventListener("fullscreenchange", exitFullscreenHandler);
            }
            document.addEventListener("keydown", keydownHandler);
        }

        return (
            <styles.startMessageContainer>
                <div className="level-label">{levelMessage}</div>
                {webScriptLoaded && level < MAX_LEVEL && (
                    <span onClick={() => startLevel()}>{buttonMessage}</span>
                )}
                {!webScriptLoaded && <span>Loading...</span>}
            </styles.startMessageContainer>
        );
    };

    /**
     * whenever the previous shape is updated,
     * depending on the level, prompt user to identify which
     * shape he just saw
     *
     * FEATURE DISABLED FOR NOW, DO NOT DELETE
     */
    // useEffect(() => {
    //   if (
    //     hasRunAtLeastOnce === true &&
    //     scoreModalActive === false
    //   ) {
    //     const chanceToScore = Math.random();

    //     switch(level) {
    //       // polling rate: 80%
    //       case 1: {
    //         if (chanceToScore >= 0.2) {
    //           setScoreModalActive(true);
    //         }

    //         break;
    //       }

    //       // polling rate: 25%
    //       case 2: {
    //         if (chanceToScore >= 0.75) {
    //           setScoreModalActive(true);
    //         }

    //         break;
    //       }

    //       default: {
    //         break;
    //       }
    //     }
    //   }

    // }, [previousShape])

    const processPlayerAnswer = (answer: boolean) => {
        if (answer === true) {
            sessionScore.correctAnswers++;
        }
        else {
            sessionScore.incorrectAnswers++;
        }

        setScoreModalActive(false);
        unpauseLevel();
    };

    const modal = (
        <div>
            {scoreModalActive === true && previousShape !== undefined && (
                <ScoringModal
                    title="Pick the last shape you saw"
                    level={level}
                    active={scoreModalActive}
                    previousShape={previousShape}
                    wasCorrect={(answer: boolean) => {
                        processPlayerAnswer(answer);
                    }}
                />
            )}
        </div>
    );

    const displayGame = (): JSX.Element => {
        if (activeShape !== undefined) {
            const canvas = (
                <ShapeCanvas
                    shapes={[activeShape]}
                    paused={paused}
                    frameTime={1000 / FRAME_RATE}
                />
            );

            return scoreModalActive ? modal : canvas;
        }
        else {
            /**
             * a lot of my troubles seem to be stemming from the initial pass
             * and stuff not being loaded. Hopefully this fixes them.
             */
            return <div></div>;
        }
    };

    let body;

    // /**
    //  * If the session is completed, we don't want to show anything
    //  * on screen, but only show the completion code window.
    //  */
    // if (isSessionCompleted === true) {
    //     const completionCode = User.getCompletionCode();
    //     if (typeof completionCode === 'string') {
    //         showCompleteWindow(completionCode);
    //     }
    //     else {
    //         showErrorWindow();
    //     }

    //     body = <div></div>;
    // }
    /**
     * If session complete state is not ready yet (undefined),
     * then show an empty screen with a line of test.
     */
    if (isSessionCompleted === undefined) {
        body = <Modal>Checking task session progress</Modal>;
    }
    else if (isSessionCompleted) {
        return (
            <styles.startMessageContainer>
                <div className="level-label">Please close this browser window</div>
            </styles.startMessageContainer>
        )
    }
    else if (openModal === true && started === false) {
        body = <Instructions closeModal={setOpenModal} close={false}></Instructions>;
    }
    else {
        body = (
            started === true && paused === false
            ? displayGame()
            : displayStartMessage()
        );
    }

    return (
        <div className="App">
            <GlobalStyle/>
            {body}
        </div>
    );

}

export default App;
