/**
 * Usage:
        // loadScript("https://atrace-cdn.s3.ap-southeast-2.amazonaws.com/WebPlatform/Bundles/Latest/trainingDot.js")
        // .then( data  => {
        //   console.log("Script loaded successfully", data);    
        // })
        // .catch( err => {
        //   console.log(err);
        // });
 * 
 * 
 * @param file_url given url to load the script fomr
 * @param async if the script element is set to async, default is true
 * @param type script element type to set, default is "text/javascript"
 * @returns 
 */
export const loadScript = (file_url: string, async = true, type = "text/javascript") => {
    return new Promise((resolve, reject) => {
            const scriptEle = document.createElement("script");
            scriptEle.type = type;
            scriptEle.async = async;
            scriptEle.src =file_url;

            scriptEle.addEventListener("load", (ev) => {
                resolve({ status: true });
            });

            scriptEle.addEventListener("error", (ev) => {
                reject({
                    status: false,
                    message: `Failed to load the script ${file_url}`
                });
                scriptEle.remove();
            });

            document.body.appendChild(scriptEle);
    });
};