import styled from 'styled-components';


export const startMessageContainer = styled.div`
    span {
        font-size: 28px;
        border: 2px solid grey;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        padding: 15px;

        :hover {
            box-shadow: 5px 5px 5px grey;
        }
    }

    div.level-label {
        font-size: 28px;
    }

    background-image: linear-gradient(white, lightgrey);
    line-height: 10;
    min-height: 100vh;
    text-align: center;
    justify-content: center;
`;
