import { AppColor, getRandomColor } from "../view/colorGenerator";
import { Position, ShapeType } from "./shapes";


export type TransientShapeStatus =
    | "appearing"
    | "visible"
    | "disappearing"
    | "invisible"


export abstract class Shape {
    public color: AppColor = getRandomColor();

    public status: TransientShapeStatus = "invisible";

    /**
     * graphics origin is in the upper left corner of the image in relation to the
     * containing html element. These are normalized CSS pixels, calculated by multiplying
     * the relative position by the document width and height
     * */
    protected graphicsOrigin: Position = { X: 0, Y: 0 };
    public get GraphicsOrigin(): Position { return this.graphicsOrigin };
    public set GraphicsOrigin(newPosition: Position) {
        this.graphicsOrigin = newPosition;
    }

    /** this position is expressed in terms of 0.0 (0%) to 1.0 (100%) of the native display */
    protected relativePosition: Position = { X: 0, Y: 0 };
    public get RelativePosition(): Position { return this.relativePosition };
    public set RelativePosition(newPosition: Position) {
        this.relativePosition = newPosition;
    }

    protected label: string = "";
    public get Label(): string { return this.label; }

    /** expressed in CSS pixels */
    protected height: number = 0;
    public get Height(): number { return this.height; }

    /** expressed in CSS pixels */
    protected width: number = 0;
    public get Width(): number { return this.width; }

    protected abstract shape: ShapeType;
    public get ShapeType(): ShapeType { return this.shape; }

    abstract Intersects(other: Shape): boolean;
}
