import { Shape } from "./shapeBase";
import { Position, ShapeType } from "./shapes";


export class Invader extends Shape {

    private static invaderCounter: number = 0;

    protected shape: ShapeType = "invader";
    public get ShapeType(): ShapeType { return this.shape; }

    constructor(origin: Position) {
        super();

        this.label = `invader_${Invader.invaderCounter}`;
        Invader.invaderCounter++;
        this.relativePosition = origin;

        /**
        * NOTE: the space invader raw PNG is 113 x 85 pixels.
        * for performance reasons we are resizing to 100x75
        */
        this.height = 37.5;
        this.width = 50;
    }

    public Intersects(other: Shape): boolean {
        return false;
    }
}
