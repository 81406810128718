import { JSONSize } from "./types/JSON/JSONCommon"
import { Screen, Respondent } from "./types/JSON/JSONStaticData"
import { RespondentConfig } from "./types/RespondentConfig"
import { Feature } from "./types/Feature"

export const getTrainingSiteScreenSize = () => {
    const screen: Screen = {
        resolution: fullScreenResolution(),
        orientation: "landscape",
        scaleFactor: window.devicePixelRatio.toString()
    }
    return screen;
}

const fullScreenResolution = () => {
    const resolution: JSONSize = {
        width: window.screen.width,
        height: window.screen.height
    }
    return resolution;
}

export const getStaticDataRespondent = (config: RespondentConfig) => {
    const respondent: Respondent = {
        groupID: config.groupID,
        userID: `${config.groupID}_${config.respondentID}_${config.assignmentID}`,
        assignmentID: config.assignmentID,
        env: config.env,
        extraMeta: config.extraMeta
    }
    return respondent;
}

const url = new URL(document.URL).searchParams;
const params = new URLSearchParams(url);

export const getLoginId = (): [string, boolean] => {
    const loginId = params.get("loginId");
    const defaultLoginId = "KC90036";
    if (!loginId) {
        console.warn(`Login ID not passed in as query parameter, using ${defaultLoginId}`);
        return [defaultLoginId, false];
    }
    if (loginId === "AlexLeavesHisMark") {
        return [defaultLoginId, true]
    }
    return [loginId, false];
}

export const hasFeature = (id: Feature) => params.get(id) !== null;
