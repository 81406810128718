export type RgbColor = {
    red: number,
    green: number,
    blue: number
};

export type AppColor = {
    RGB: RgbColor,
    Hex: string
};

export const ToRgb = (hex: string): RgbColor => {
    const hexRed = hex.substring(0, 2);
    const decRed = parseInt(hexRed, 16);

    const hexGreen = hex.substring(2, 4);
    const decGreen = parseInt(hexGreen, 16);

    const hexBlue = hex.substring(4, 6);
    const decBlue = parseInt(hexBlue, 16);

    return {
        red: decRed,
        green: decGreen,
        blue: decBlue
    };
};

export const ToHex = (rgb: RgbColor): string => {
    const r = parseInt(rgb.red.toString(), 16);
    const g = parseInt(rgb.green.toString(), 16);
    const b = parseInt(rgb.blue.toString(), 16);

    const hex: string = `${r.toString}+${g.toString}+${b.toString()}`;
    return hex;
}

export const getRandomColor = (): AppColor => {

    // converts to 16,777,215 in base 10
    const maxVal = 0xFFFFFF;
    let randomNumber = Math.floor(Math.random() * maxVal);
    const hex = randomNumber.toString(16);

    const rgbColor = ToRgb(hex);

    const color: AppColor = {
        RGB: rgbColor,
        Hex: `#${hex}`
    };

    return color;
}
