import { S3Client, GetObjectCommand, ListObjectsV2Command, PutObjectCommand } from '@aws-sdk/client-s3';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

type Environment = "prod" | "dev"

const Region = "ap-southeast-2"

const Config = {
    prod: {
        configsBucket: 'atrace-respondent-configs',
        uploadsBucket: 'atrace-app-uploads',
        identityPoolId: 'ap-southeast-2:25feef0d-bde0-4fe8-81b7-e02276c004d0'
    },
    dev: {
        configsBucket: 'atrace-respondent-configs-dev',
        uploadsBucket: 'atrace-app-uploads-dev',
        identityPoolId: 'ap-southeast-2:be78eddf-9337-4ec0-922b-8f1b07bfeee7'
    }
}

export default class S3 {
    configsBucket: string;
    uploadsBucket: string;

    private _bSkipUploads: boolean;
    private _client: S3Client;

    /**
     *
     * @param env One of "dev" or "prod".
     * @param bSkipUploads Setting this to true skips all uploads, downloads work normally.
     */
    constructor(env: Environment, bSkipUploads: boolean = false) {
        this.configsBucket = Config[env].configsBucket;
        this.uploadsBucket = Config[env].uploadsBucket;
        this._bSkipUploads = bSkipUploads;

        this._client = new S3Client({
            useAccelerateEndpoint: true,
            maxAttempts: 5,
            region: Region,
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: Region }),
                identityPoolId: Config[env].identityPoolId
            })
        })
    }

    async getObject<T extends object>(key: string, bucket: string = this.configsBucket): Promise<T> {
        const cmd = new GetObjectCommand({ 
            Bucket: bucket, 
            Key: key, 
            ResponseCacheControl: 'no-store, no-cache, must-revalidate'
        });
        const resp = await this._client.send(cmd);

        if (!resp.Body)
            throw new Error("getObject body was empty");

        const content = await resp.Body.transformToString();

        return JSON.parse(content) as T;
    }

    async doesObjectExist(key: string, bucket: string = this.uploadsBucket): Promise<boolean> {
        const cmd = new ListObjectsV2Command({ Bucket: bucket, Prefix: key })
        const resp = await this._client.send(cmd);
        return resp.KeyCount == 1;
    }

    async postObject(obj: object, key: string, bucket: string = this.uploadsBucket): Promise<object> {
        if (this._bSkipUploads)
            return obj;

        const body = JSON.stringify(obj, null, 2);
        const cmd = new PutObjectCommand({ Bucket: bucket, Key: key, ContentType: 'text/json', Body: body });

        await this._client.send(cmd);
        return obj;
    }

    async postBlob(blob: Blob, contentType: string, key: string, bucket: string = this.uploadsBucket): Promise<Blob> {
        if (this._bSkipUploads)
            return blob;

        const cmd = new PutObjectCommand({ Bucket: bucket, Key: key, ContentType: contentType, Body: blob });
        await this._client.send(cmd);
        return blob;
    }
}
